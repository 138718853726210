/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import '../styles/components/AiBuilderAgent.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAgent } from '../app/redux/slices/liveAgentSlice';
import { BiLike, BiDislike } from "react-icons/bi";
import { RiFeedbackLine } from "react-icons/ri";
import { MdOutlineFileDownload } from "react-icons/md";
import { VscVscode } from "react-icons/vsc";
import { FaRegCopy } from "react-icons/fa6";

import RequirementsFileForm from './RequirementsFileForm';
import UrlInputForm from './UrlInputForm';
import VerifyAWebisteResult from './agentResults/VerifyAWebisteResult';
import FeedbackModal from './FeedbackModal';
import TestAnApiForm from './agentForms/TestAnApiForm';
import TestAnApiResult from './agentResults/TestAnApiResult';
import CreateAutomationForm from './agentForms/CreateAutomationForm';
import ApiAutomationResult from './agentResults/ApiAutomationResult';
import { postApiCall } from '../app/apiCalls';
import UiAutomationResult from './agentResults/UiAutomationResult';
import LoadTestForm from './agentForms/LoadTestForm';
import LoadTestResult from './agentResults/LoadTestResult';
import WebappTestForm from './agentForms/WebappTestForm';
import WebappTestResult from './agentResults/WebappTestResult';

const AiBuilderAgent = () => {
  const { selectedAgent } = useSelector((state) => state.liveAgent);
  const dispatch = useDispatch();

  const [showResult, setShowResult] = React.useState(false);
  const [resultData, setResultData] = React.useState();
  const [presignedURL, setPresignedURL] = useState('');
  const [resultLiked, setResultLiked] = React.useState(false);
  const [resultDisliked, setResultDisliked] = React.useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [fileContentToCopy, setFileContentToCopy] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!selectedAgent || Object.keys(selectedAgent).length === 0) {
      dispatch(setSelectedAgent(JSON.parse(localStorage.getItem('selectedAgent'))));
    }
  }, [dispatch, selectedAgent]);

  const handleDownload = async () => {
    try {
      if (selectedAgent?.id === 'create-api-automation-code' || selectedAgent?.id === 'agent-create-ui-automation-code') {
        const downloadUrl = await fetchGeneratedFile(resultData.id);
        if(downloadUrl) {
          const link = document.createElement('a');
          link.href = downloadUrl; // Use the presigned URL directly
          link.setAttribute('download', resultData.generated_automation_file_s3_object_key.split('/').pop()); // Set the filename for download
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        return;
      }
      let csvData = [];
      // console.log(resultData);
  
      if (['create-test-scenarios', 'write-uat-tests', 'generate-test-cases'].includes(selectedAgent.id)) {
          const headers = resultData.headers;
          csvData.push(headers.join(",")); // Add headers as the first row
  
          resultData?.rows.forEach(row => {
              const formattedRow = row.map(field => {
                  // Escape special characters (", newline, etc.)
                  if (typeof field === "string") {
                      field = field.replace(/"/g, '""'); // Escape quotes by doubling them
                      if (field.includes(",") || field.includes("\n")) {
                          field = `"${field}"`; // Wrap in quotes if field contains commas or newlines
                      }
                  }
                  return field;
              });
              csvData.push(formattedRow.join(",")); // Add formatted row
          });
      }
      if (['verify-a-website'].includes(selectedAgent.id)) {
        
      }

      // Create CSV file
      const file = new Blob([csvData.join("\n")], { type: "text/csv" });
      const url = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Result.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  } catch (error) {
      alert("Failed to download the result");
      console.log(error);
  }  
  };

  const handleLike = () => {
    postApiCall(`/agent-runs/${resultData?.agent_run_id}/feedback`, { liked: true, disliked: false })
      .then((response) => {
        console.log(response);
        setResultLiked(true);
        setResultDisliked(false);
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to like the result");
      });
  }

  const handleDislike = () => {
    postApiCall(`/agent-runs/${resultData?.agent_run_id}/feedback`, { liked: false, disliked: true })
      .then((response) => {
        console.log(response);
        setResultLiked(false);
        setResultDisliked(true);
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to dislike the result");
      });
  }

  const handleFeedback = () => {
    setIsFeedbackModalOpen(true);
  }

  const handleOpenInVsCode = async () => {
    try {
      const downloadUrl = await fetchGeneratedFile(resultData.id);

      // Step 1: Download the file using the presigned URL
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error('Failed to download the file');
      }
      const blob = await response.blob();
      
      // Step 2: Create a local URL for the file
      const file = new File([blob], 'generated.feature', { type: 'text/plain' });

      // Step 3: Save the file to the local filesystem
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: 'generated.feature',
        types: [{
          description: 'Feature Files',
          accept: { 'text/plain': ['.feature'] },
        }],
      });

      const writableStream = await fileHandle.createWritable();
      await writableStream.write(blob);
      await writableStream.close();

      // Step 4: Open the file in VS Code
      const vscodeUrl = `vscode://file/${downloadUrl}`;
      window.open(vscodeUrl, '_blank');
    } catch (error) {
      console.error('Error opening file in VS Code:', error);
    }
  };

  const handleOpenInCursor = async () => {
      try {
        const downloadUrl = await fetchGeneratedFile(resultData.id);

        // Step 1: Download the file using the presigned URL
        const response = await fetch(downloadUrl);
        if (!response.ok) {
          throw new Error('Failed to download the file');
        }
        const blob = await response.blob();
        
        // Step 2: Create a local URL for the file
        const file = new File([blob], 'generated.feature', { type: 'text/plain' });
  
        // Step 3: Save the file to the local filesystem
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: 'generated.feature',
          types: [{
            description: 'Feature Files',
            accept: { 'text/plain': ['.feature'] },
          }],
        });
  
        const writableStream = await fileHandle.createWritable();
        await writableStream.write(blob);
        await writableStream.close();
  
        // Step 4: Open the file in VS Code
        const vscodeUrl = `cursor://file/${downloadUrl}`;
        window.open(vscodeUrl, '_blank');
      } catch (error) {
        console.error('Error opening file in VS Code:', error);
      }
  }

  const fetchGeneratedFile = async (automationId) => {
    try {
      const response = await postApiCall('/automate-ai/presigned-url', {
        file_name: `${automationId}/features/generated.feature`,
        method: 'get_object'
      });
      if (response?.['pre-signed_url']) {
        setPresignedURL(response?.['pre-signed_url']);
        return response?.['pre-signed_url'];
      } else {
        throw new Error("Failed to get presigned URL");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(fileContentToCopy)
      .then(() => {
        alert('File content copied to clipboard');
      })
      .catch((error) => {
        console.error('Failed to copy file content to clipboard:', error);
      });
  }

  const formTypes = {
    'create-test-scenarios': <RequirementsFileForm selectedAgent={selectedAgent} setShowResult={setShowResult} setResultData={setResultData} />,
    'write-uat-tests': <RequirementsFileForm selectedAgent={selectedAgent} setShowResult={setShowResult} setResultData={setResultData} />,
    'generate-test-cases': <RequirementsFileForm selectedAgent={selectedAgent} setShowResult={setShowResult} setResultData={setResultData} />,
    'verify-a-website': <UrlInputForm selectedAgent={selectedAgent} setShowResult={setShowResult} setResultData={setResultData} />,
    'test-an-api-with-postman': <TestAnApiForm selectedAgent={selectedAgent} apiTestType="postman" setShowResult={setShowResult} setResultData={setResultData} />,
    'test-an-api-with-restassured': <TestAnApiForm selectedAgent={selectedAgent} apiTestType="rest-assured" setShowResult={setShowResult} setResultData={setResultData} />,
    'test-an-api-with-karate': <TestAnApiForm selectedAgent={selectedAgent} apiTestType="karate" setShowResult={setShowResult} setResultData={setResultData} />,
    'test-an-api-with-robot': <TestAnApiForm selectedAgent={selectedAgent} apiTestType="robot" setShowResult={setShowResult} setResultData={setResultData} />,
    'create-api-automation-code': <CreateAutomationForm selectedAgent={selectedAgent} setShowResult={setShowResult} setResultData={setResultData} />,
    'create-ui-automation-code': <CreateAutomationForm selectedAgent={selectedAgent} setShowResult={setShowResult} setResultData={setResultData} />,
    'performance-test-a-webapp-with-selenium-java': <LoadTestForm selectedAgent={selectedAgent} testingFramework={"selenium"} testingLanguage={"java"} setShowResult={setShowResult} setResultData={setResultData} />,
    'performance-test-a-webapp-with-selenium-python': <LoadTestForm selectedAgent={selectedAgent} testingFramework={"selenium"} testingLanguage={"python"} setShowResult={setShowResult} setResultData={setResultData} />,
    'performance-test-a-webapp-with-playwright-java': <LoadTestForm selectedAgent={selectedAgent} testingFramework={"playwright"} testingLanguage={"java"} setShowResult={setShowResult} setResultData={setResultData} />,
    'performance-test-a-webapp-with-playwright-python': <LoadTestForm selectedAgent={selectedAgent} testingFramework={"playwright"} testingLanguage={"python"} setShowResult={setShowResult} setResultData={setResultData} />,
    'performance-test-a-webapp-with-robot-python': <LoadTestForm selectedAgent={selectedAgent} testingFramework={"robot"} testingLanguage={"python"} setShowResult={setShowResult} setResultData={setResultData} />,
    'performance-test-a-webapp-with-testng-java': <LoadTestForm selectedAgent={selectedAgent} testingFramework={"testng"} testingLanguage={"java"} setShowResult={setShowResult} setResultData={setResultData} />,
    'test-a-webapp-with-selenium-java': <WebappTestForm selectedAgent={selectedAgent} testingFramework={"selenium"} testingLanguage={"java"} setShowResult={setShowResult} setResultData={setResultData} />,
    'test-a-webapp-with-selenium-python': <WebappTestForm selectedAgent={selectedAgent} testingFramework={"selenium"} testingLanguage={"python"} setShowResult={setShowResult} setResultData={setResultData} />,
    'test-a-webapp-with-playwright-java': <WebappTestForm selectedAgent={selectedAgent} testingFramework={"playwright"} testingLanguage={"java"} setShowResult={setShowResult} setResultData={setResultData} />,
    'test-a-webapp-with-playwright-python': <WebappTestForm selectedAgent={selectedAgent} testingFramework={"playwright"} testingLanguage={"python"} setShowResult={setShowResult} setResultData={setResultData} />,
    'test-a-webapp-with-robot-python': <WebappTestForm selectedAgent={selectedAgent} testingFramework={"robot"} testingLanguage={"python"} setShowResult={setShowResult} setResultData={setResultData} />,
    'test-a-webapp-with-testng-java': <WebappTestForm selectedAgent={selectedAgent} testingFramework={"testng"} testingLanguage={"java"} setShowResult={setShowResult} setResultData={setResultData} />,
  }

  return (
    <main id="ai-builders-main w-100">
      <section className="agent-activate-section d-flex flex-column justify-content-start align-items-start">
        <div className='d-flex flex-row justify-content-start align-items-center'>
          {selectedAgent?.image?.length > 0 ? <img className="agent-image" src={require(`../${selectedAgent?.image}`)} alt="AI Builder" /> : null}
          <div className='d-flex flex-row mt-3 align-items-start' style={{ marginLeft: "1.7vw" }}>
            <div className="d-flex flex-column align-items-start justify-content-start">
              <h6 className="agent-name" style={{ fontSize: "1.3vw" }}>{selectedAgent?.name}</h6>
              <p className="agent-description">{selectedAgent?.description}</p>
            </div>
            <button className="follow-button">Follow</button>
          </div>
        </div>

        {selectedAgent?.id?.length > 0 ? formTypes[selectedAgent.id] : null}

      </section>

      {/* Show Result */}
      {showResult ?
        <section className="agent-result-section d-flex flex-column justify-content-start align-items-start">
          <div className='agent-result-header w-100 d-flex flex-row justify-content-between align-items-center'>
            {selectedAgent?.id === 'create-api-automation-code' ? <h5>Feature file - {resultData?.testrun_name}</h5> : null}
            {selectedAgent?.id === 'create-ui-automation-code' ? <h5>UI Automation Code - {resultData?.testrun_name}</h5> : null}
            {selectedAgent?.id !== 'create-api-automation-code' && selectedAgent?.id !== 'create-ui-automation-code' ? <h5>{selectedAgent?.name} Result:</h5> : null}
            <div className='agent-result-header-actions d-flex flex-row align-items-center'>
              {selectedAgent?.id === 'create-api-automation-code' ?
                <>
                  Open with:
                  <button className='action-text-btns disabled' disabled={"true"} onClick={() => handleOpenInCursor()}><strong>Cursor</strong></button>
                  <button className='action-text-btns disabled' disabled={"true"} onClick={() => handleOpenInVsCode()}><strong>Vs Code</strong></button>
                  |
                </>
                : null}
              {selectedAgent?.id === 'create-ui-automation-code' ? <FaRegCopy onClick={() => handleCopyToClipboard()} /> : null}
              <MdOutlineFileDownload onClick={() => handleDownload()} />
              <BiLike style={resultLiked ? { color: "white", backgroundColor: "#20264D" } : {}} onClick={() => handleLike()} />
              <BiDislike style={resultDisliked ? { color: "white", backgroundColor: "#20264D" } : {}} onClick={() => handleDislike()} />
              <RiFeedbackLine onClick={() => handleFeedback()} />
            </div>
          </div>

          <div class="table-responsive w-100">
            {(resultData !== undefined && ['create-test-scenarios', 'generate-test-cases', 'write-uat-tests'].includes(selectedAgent?.id)) ? <table class="table table-bordered table-striped agent-result-table">
              <thead class="thead-dark">
                <tr>
                  {resultData?.headers?.length > 0 ? resultData?.headers.map(header => <th key={header} scope="col">{header}</th>) : null}
                </tr>
              </thead>
              <tbody>
                {resultData?.rows?.length > 0 ? resultData?.rows.map((row, index) => (
                  <tr key={index}>
                    {row.map(cell => <td key={cell}>{cell}</td>)}
                  </tr>
                )) : null}
              </tbody>
            </table> : null}
            {(resultData !== undefined && selectedAgent?.id === "verify-a-website") ? <VerifyAWebisteResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "test-an-api-with-postman") ? <TestAnApiResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "test-an-api-with-restassured") ? <TestAnApiResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "test-an-api-with-karate") ? <TestAnApiResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "test-an-api-with-robot") ? <TestAnApiResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === 'create-api-automation-code') ? (<ApiAutomationResult resultData={resultData} presignedURL={presignedURL} setPresignedURL={setPresignedURL} />) : null}
            {(resultData !== undefined && selectedAgent?.id === 'create-ui-automation-code') ? (<UiAutomationResult resultData={resultData} presignedURL={presignedURL} setPresignedURL={setPresignedURL} setFileContentToCopy={setFileContentToCopy} />) : null}
            {(resultData !== undefined && selectedAgent?.id === "performance-test-a-webapp-with-selenium-java") ? <LoadTestResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "performance-test-a-webapp-with-selenium-python") ? <LoadTestResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "performance-test-a-webapp-with-playwright-java") ? <LoadTestResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "performance-test-a-webapp-with-playwright-python") ? <LoadTestResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "performance-test-a-webapp-with-robot-python") ? <LoadTestResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "performance-test-a-webapp-with-testng-java") ? <LoadTestResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "test-a-webapp-with-selenium-java") ? <WebappTestResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "test-a-webapp-with-selenium-python") ? <WebappTestResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "test-a-webapp-with-playwright-java") ? <WebappTestResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "test-a-webapp-with-playwright-python") ? <WebappTestResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "test-a-webapp-with-robot-python") ? <WebappTestResult resultData={resultData} /> : null}
            {(resultData !== undefined && selectedAgent?.id === "test-a-webapp-with-testng-java") ? <WebappTestResult resultData={resultData} /> : null}
          </div>
        </section>
        : null}
      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
        handleSubmit={(feedbackText) => {
          console.log(feedbackText);
          // Add your feedback handling logic here
          postApiCall(`/agent-runs/${resultData?.agent_run_id}/feedback`, { feedback: feedbackText })
            .then((response) => {
              console.log('Feedback submitted successfully:', response);
              alert('Feedback submitted successfully');
              setIsFeedbackModalOpen(false);
            })
            .catch((error) => {
              console.error('Failed to submit feedback:', error);
              alert('Failed to submit feedback');
            })
        }}
      />
    </main>
  )
}

export default AiBuilderAgent