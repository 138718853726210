import React from 'react';
import ReactECharts from 'echarts-for-react';
import '../../styles/components/agentResults/LoadTestResult.css';
import { formatCurrentDateTime } from '../../utils/formatCurrentDateTime';

const LoadTestResult = ({ resultData }) => {
  if (!resultData) return null;

  const { 
    testrun_status_details: details,
    pass_fail_count_per_user,
    result_execution_time_per_user
  } = resultData;

  // Prepare data for pass/fail count chart
  const userIndices = pass_fail_count_per_user.map(item => `User ${item.user_index}`);
  const passedCounts = pass_fail_count_per_user.map(item => parseInt(item.passed));
  const failedCounts = pass_fail_count_per_user.map(item => parseInt(item.failed));

  // Bar Chart Options for Pass/Fail Distribution
  const passFailBarOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: ['Passed', 'Failed']
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: userIndices,
      axisTick: {
        alignWithLabel: true
      },
      name: 'Users',
      nameLocation: 'middle',
      nameGap: 35
    },
    yAxis: {
      type: 'value',
      name: 'Count',
      nameLocation: 'middle',
      nameGap: 40
    },
    series: [
      {
        name: 'Passed',
        type: 'bar',
        stack: 'total',
        data: passedCounts,
        itemStyle: {
          color: '#28a745'
        }
      },
      {
        name: 'Failed',
        type: 'bar',
        stack: 'total',
        data: failedCounts,
        itemStyle: {
          color: '#DE4C44'
        }
      }
    ]
  };

  // Prepare data for execution time chart
  const executionTimeOptions = {
    tooltip: {
      trigger: 'axis',
      formatter: function(params) {
        return `${params[0].name}<br/>${params[0].value / 1000}s`;
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: result_execution_time_per_user.map(item => `User ${item.user_index}`),
      name: 'Users',
      nameLocation: 'middle',
      nameGap: 35,
      boundaryGap: false
    },
    yAxis: {
      type: 'value',
      name: 'Time (ms)',
      nameLocation: 'middle',
      nameGap: 40,
      axisLabel: {
        formatter: function(value) {
          return `${value / 1000}s`;
        }
      }
    },
    series: [{
      data: result_execution_time_per_user.map(item => item.duration),
      type: 'line',
      smooth: true,
      symbolSize: 8,
      itemStyle: {
        color: '#20264D'
      },
      lineStyle: {
        width: 3
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0,
            color: 'rgba(32, 38, 77, 0.3)'
          }, {
            offset: 1,
            color: 'rgba(32, 38, 77, 0)'
          }]
        }
      }
    }]
  };

  // Extract unique browser names
  const uniqueBrowsers = [...new Set(details.map(detail => detail.browser_name))].join(', ');

  return (
    <div className='load-test-result'>
      <div className='load-test-result-header'>
        <p>The Load testing was successfully completed at {formatCurrentDateTime()}.</p>
      </div>

      <div className='load-test-stats-grid'>
        <div className='stat-card'>
          <h3>{details[0].total}</h3>
          <p>Total Tests</p>
        </div>
        
        <div className='stat-card success'>
          <h3>{details[0].pass}</h3>
          <p>Passed</p>
        </div>
        
        <div className='stat-card error'>
          <h3>{details[0].fail}</h3>
          <p>Failed</p>
        </div>
        
        <div className='stat-card'>
          <h3>{(details[0].time_taken).toFixed(1)}s</h3>
          <p>Total Time</p>
        </div>
        
        <div className='stat-card'>
          <h3>{uniqueBrowsers}</h3>
          <p>Browser{uniqueBrowsers.includes(',') ? 's' : ''}</p>
        </div>
      </div>

      <div className='load-test-charts'>
        <div className='chart-container'>
          <h4>Pass/Fail Distribution per User</h4>
          <ReactECharts option={passFailBarOptions} style={{ height: '400px' }} />
        </div>
        
        <div className='chart-container'>
          <h4>Execution Time per User</h4>
          <ReactECharts option={executionTimeOptions} style={{ height: '400px' }} />
        </div>
      </div>
    </div>
  );
};

export default LoadTestResult; 