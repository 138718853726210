import { getApiCall } from "../app/apiCalls";

export const getStatus = async (id, agentType, userInfo = {}) => {
  return getApiCall(`/status?testrun_id=${id}&agent_type=${agentType}`, userInfo)
  .then((res) => {
    return res;
  })
  .catch((err) => {
    console.log(err);
    return err;
  })
};

export const pollStatus = async (id, agentType, userInfo = {}) => {
  const maxPollCount = 50;
  const pollTimeout = 12000;

  let pollCount = 0;
  while (true) {
    let res = await getStatus(id, agentType, userInfo);
    if (res instanceof Error) {
      throw res;
    }
    if ((res?.status_code === 200 && res?.data?.testrun_status === "completed") || pollCount > maxPollCount) {
      res = await getStatus(id, agentType, userInfo);
      return res;
    }
    await new Promise(resolve => setTimeout(resolve, pollTimeout));
    pollCount++;
  }
};
