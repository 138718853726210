import React, { useState } from 'react';
import { getApiCall, postApiCall } from '../../app/apiCalls/index';
import '../../styles/components/agentForms/CreateAutomationForm.css';

const CreateAutomationForm = ({ selectedAgent, setShowResult, setResultData }) => {
  const [formData, setFormData] = useState({
    testrun_name: '',
    test_description: '',
    automation_type: selectedAgent.id === 'create-api-automation-code' ? 'api_automation' : 'ui_automation',
    feature_recording_file_s3_object_key: '',
    api_endpoint_url: ''
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isGeneratingResult, setIsGeneratingResult] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a file first');
      return;
    }

    try {
      const fileType = 'har';
      console.log(selectedFile);
      const s3ObjectKey = await uploadFileToS3(selectedFile, selectedFile.name, fileType);
      setFormData(prev => ({
        ...prev,
        feature_recording_file_s3_object_key: s3ObjectKey
      }));
      setSelectedFile(null);
    } catch (error) {
      setErrorMessage(`Failed to upload file. Please try again.`);
    }
  };

  const uploadFileToS3 = async (file, fileName, fileType) => {
    try {
      const response = await postApiCall(`/automate-ai/presigned-url`, {
        file_name: fileName,
        method: "put_object"
      });
      console.log("response ", response);
      const preSignedURL = response['pre-signed_url'];
      const requestOptions = {
        method: 'PUT',
        body: file,
        redirect: 'follow',
      };
      fetch(preSignedURL, requestOptions);
      
      return fileName;
    } catch (error) {
      console.error('File upload failed:', error);
      throw error;
    }
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    setShowResult(false);
    setErrorMessage("");
    setIsGeneratingResult(true);

    try {
      const response = await postApiCall('/create-automation', formData);

      const automationId = response.id;
      const agent_run_id = response?.agent_run_id;
      await pollAutomationStatus(automationId, agent_run_id);
    } catch (error) {
      console.error(error);
      setErrorMessage("Something went wrong. Please try again later");
    } finally {
      setIsGeneratingResult(false);
    }
  };

  const pollAutomationStatus = async (automationId, agent_run_id) => {
    const maxPollCount = 20;
    const pollTimeout = 1000;
    let pollCount = 0;

    while (pollCount < maxPollCount) {
      try {
        let statusResponse = await getApiCall(`/automate-ai/status/${automationId}`);
        statusResponse = { ...statusResponse, agent_run_id: agent_run_id };

        if(selectedAgent.id === 'create-api-automation-code') {
          if ( statusResponse?.status === "API Tests Generation Complete" || statusResponse?.status?.includes("Complete")) {
            setResultData(statusResponse);
            setShowResult(true);
            return;
          }
        }
        else {
          if (statusResponse?.status === "Automation Tests Generation Complete") {
            setResultData(statusResponse);
            setShowResult(true);
            return;
          }
        }
      } catch (error) {
        console.error("Error fetching automation status:", error);
      }

      // eslint-disable-next-line no-loop-func
      await new Promise(resolve => setTimeout(resolve, (pollTimeout * (pollCount + 1))));
      pollCount++;
    }

    setErrorMessage("Polling for automation status timed out. Please try again later.");
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      testrun_name: '',
      test_description: '',
      automation_type: selectedAgent.id === 'create-api-automation-code' ? 'api_automation' : 'ui_automation',
      feature_recording_file_s3_object_key: '',
      api_endpoint_url: ''
    });
    setErrorMessage("");
    setSelectedFile(null);
  };

  return (
    <form className="create-automation-form" onSubmit={handleGenerate}>
      <div className="form-container">
        <div className="basic-details-section">
          <div className="form-section bordered-container">
            <div className="form-group">
              <label>Name<span className="required">*</span></label>
              <input
                type="text"
                name="testrun_name"
                value={formData.testrun_name}
                onChange={handleInputChange}
                placeholder="Enter name"
                required
              />
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
                name="test_description"
                value={formData.test_description}
                onChange={handleInputChange}
                placeholder="Enter description"
                rows="4"
              />
            </div>
          </div>
        </div>

        <div className="conditional-fields-section">
          <div className="form-section bordered-container">

            <div className="form-group">
              <label>Feature Recording File<span className="required">*</span></label>
              <div className="file-upload-container">
                <div className="file-input-wrapper">
                  <input
                    type="file"
                    onChange={handleFileSelect}
                  />
                </div>
                <button
                  type="button"
                  className="upload-button"
                  onClick={handleUpload}
                  disabled={!selectedFile}
                >
                  Upload
                </button>
              </div>
            </div>

            <div className="form-group">
              <label>API Endpoint URL<span className="required">*</span></label>
              <input
                type="url"
                name="api_endpoint_url"
                value={formData.api_endpoint_url}
                onChange={handleInputChange}
                placeholder="Enter API endpoint URL"
                required
              />
            </div>
          </div>
        </div>
      </div>

      {isGeneratingResult && (
        <div className='d-flex flex-row w-100 justify-content-start align-items-center mt-4'>
          <div className='d-flex flex-row justify-content-center align-items-center'>
            <progress id="progress-bar" aria-label="Content loading…"></progress>
          </div>
          <p className='wait-text'>I am working on it, thanks for waiting (~1min)</p>
        </div>
      )}

      <div className="form-button-wrapper">
        <button type="button" className="reset-button" onClick={handleReset}>
          Reset
        </button>
        <button type="submit" className="test-button" disabled={isGeneratingResult}>
          {selectedAgent.id === 'create-ui-automation-code' ? 'Generate Feature Automation' : 'Generate Feature File'}
        </button>
      </div>

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </form>
  );
};

export default CreateAutomationForm; 