import React, { useEffect, useState } from 'react';
import agentsData from '../app/agentsData.json'
import AgentProfile from '../components/AgentProfile';
import '../styles/pages/Home.css';
import Sponsors from '../assets/sponsors.png';
import TestimonialsCarousel from '../components/TestimonialsCarousel';
import Agent1Image from '../assets/agent1.png';
import Agent2Image from '../assets/agent2.png';
import Agent3Image from '../assets/agent3.png';
import { useNavigate } from 'react-router-dom';
import { navigateTo } from '../utils/navigateTo';
import { setSelectedAgent } from '../app/redux/slices/liveAgentSlice';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLiveAgentSelected, setIsLiveAgentSelected] = useState(true);
  const [agents, setAgents] = useState([]);
  const agentsPerPage = 3;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultAgents = [
    {
      "id": "create-test-scenarios",
      "image": Agent1Image,
      "imageLink": 'assets/agent1.png',
      "name": "Create Test Scenarios",
      "description": "Generates detailed test scenarios from your requirements",
      "rating": 4.5,
      "executions": 640,
      "link": "/ai-builders/create-test-scenarios",
      "useCase": "test_scenario_generation"
    },
    {
      "id": "write-uat-tests",
      "image": Agent2Image,
      "imageLink": 'assets/agent2.png',
      "name": "Write UAT Tests",
      "description": "Creates UAT documents",
      "rating": 4.5,
      "executions": "1.2k",
      "link": "/ai-builders/write-uat-tests",
      "useCase": "uat_scenario_generation"
    },
    {
      "id": "generate-test-cases",
      "image": Agent3Image,
      "imageLink": 'assets/agent3.png',
      "name": "Generate Test Cases",
      "description": 'Generates comprehensive test cases from your test scenarios',
      "rating": 4.5,
      "executions": 480,
      "link": "/ai-builders/generate-test-cases",
      "useCase": "detailed_testcase_generation"
    }
  ];
  const [selectedAgents, setSelectedAgents] = useState(defaultAgents);

  const handleAgentSwap = (agentIndex) => {
    let newSelectedAgents = [...selectedAgents];
    [newSelectedAgents[1], newSelectedAgents[agentIndex]] = [newSelectedAgents[agentIndex], newSelectedAgents[1]];
    setSelectedAgents(newSelectedAgents);
  };

  const loadAgents = (initialLoad = false) => {
    const currentAgentData = isLiveAgentSelected ? agentsData.liveAgentsData : agentsData.inTrainingAgentsData;
    let newCurrentIndex = currentIndex;
    if(initialLoad) {
      setAgents(currentAgentData.slice(0, agentsPerPage));
      newCurrentIndex = agentsPerPage;
      setCurrentIndex(newCurrentIndex);
    }
    else {
      const agentsToLoad = currentAgentData.slice(currentIndex, currentIndex + agentsPerPage);
      setAgents((prevAgents) => [...prevAgents, ...agentsToLoad]);
      newCurrentIndex = currentIndex + agentsPerPage;
      setCurrentIndex(newCurrentIndex);
    }
    if ((isLiveAgentSelected && newCurrentIndex >= agentsData.liveAgentsData.length) || (!isLiveAgentSelected && newCurrentIndex >= agentsData.inTrainingAgentsData.length)) {
      document.getElementById('load-more-agents').style.display = 'none';
    }
  };

  const handleLiveAgentsClick = () => {
    setIsLiveAgentSelected(true);
    document.getElementById('live-agents-btn').classList.add('active');
    document.getElementById('in-training-agents-btn').classList.remove('active');
    document.getElementById('load-more-agents').style.display = 'block';
  };

  const handleInTrainingAgentsClick = () => {
    setIsLiveAgentSelected(false);
    document.getElementById('live-agents-btn').classList.remove('active');
    document.getElementById('in-training-agents-btn').classList.add('active');
    document.getElementById('load-more-agents').style.display = 'block';
  };

  useEffect(() => {
    loadAgents(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiveAgentSelected]);

  const handleLogin = async () => {
    localStorage.setItem("redirect_url", window.location.pathname);
    await Auth.federatedSignIn();
  }

  const handleActivate = () => {
    Auth.currentAuthenticatedUser()
    .then(user => {
      navigateTo(selectedAgents[1].link, navigate);
      const updatedSelectedAgent = { ...selectedAgents[1], image: selectedAgents[1].imageLink };
      console.log(updatedSelectedAgent);
      localStorage.setItem("selectedAgent", JSON.stringify(updatedSelectedAgent));
      dispatch(setSelectedAgent({ ...selectedAgents[1], image: selectedAgents[1].imageLink }));
    })
    .catch((error) => {
      handleLogin();
      console.log(error);
    });
  };

  return (
    <section className="w-100">
      {/* Quality Engineering Team Header */}
      <section className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="page-header">
          Hire your <span style={{ color: "#3BB879" }}>Quality Engineering</span> team
        </h1>
        <p className="page-subheader">
          Professional <span>AI Agents</span> who execute real<br />
          <span>day-to-day tasks</span>
        </p>
      </section>

      {/* Quality Engineering Team Container */}
      <section className="w-100 d-flex flex-column justify-content-center align-items-center quality-engineering-team-container">
        {/* Semicircle bottom */}
        <div className="quality-engineering-team-semicircle-bottom1"></div>

        {/* Semicircle top */}
        <div className="quality-engineering-team-ellipse1"></div>
        <div className="quality-engineering-team-ellipse2"></div>
        <div className="quality-engineering-team-ellipse3"></div>
        <div className="quality-engineering-team-selected-agent"></div>

        {/* Vectors */}
        <div className="quality-engineering-team-vector1"></div>
        <div className="quality-engineering-team-vector2"></div>

        {/* Agents */}
        <div className="quality-engineering-team-agent1" onClick={() => handleAgentSwap(0)} style={{ backgroundImage: `url(${selectedAgents[0].image})` }}></div>
        <div className="quality-engineering-team-agent1-desc">
          <p>{selectedAgents[0].name}</p>
        </div>
        <div className="quality-engineering-team-agent3" onClick={() => handleAgentSwap(2)} style={{ backgroundImage: `url(${selectedAgents[2].image})` }}></div>
        <div className="quality-engineering-team-agent3-desc">
          <p>{selectedAgents[2].name}</p>
        </div>

        {/* Selected Agent */}
        <div className="quality-engineering-team-selected-agent" style={{ backgroundImage: `url(${selectedAgents[1].image})` }}></div>
        <div className="quality-engineering-team-selected-agent-content">
          <h4>{selectedAgents[1].name}</h4>
          <p>
            {selectedAgents[1].description}
          </p>
          <button onClick={() => handleActivate()}>Activate</button>
        </div>
      </section>

      {/* Sponsored by section */}
      <section className='sponsors-section w-100 d-flex flex-column justify-content-center align-items-center'>
        <img className='sponsors-image' src={Sponsors} alt="Sponsors" />
      </section>

      {/* Live AI Builders / in training sections */}
      <section className="live-aibuilders-in-training-section w-100 d-flex flex-column align-items-center">
        <div className="live-intraining-options d-flex flex-row justify-content-center align-items-center">
          <p onClick={() => handleLiveAgentsClick()} id="live-agents-btn" className="active" style={{ borderRight: "1px solid rgba(0, 38, 77, 0.24);" }}>Live AI Agents</p>
          <p onClick={() => handleInTrainingAgentsClick()} id="in-training-agents-btn">In Training</p>
        </div>

        <div id="agents-container" className="section-content d-flex flex-column justify-content-center">
          <div className="d-flex flex-row justify-content-center flex-wrap gap-5" id="agents-row">
            {/* Agent cards will be injected here */}
            {agents.map((agent, index) => (
              <AgentProfile key={`${agent.id}${index}`} agentData={agent} isLiveAgent={isLiveAgentSelected} />
            ))}
          </div>
        </div>

        <button id="load-more-agents" onClick={() => loadAgents()}>Load More Agents</button>
      </section>

      {/* Clients Testimonials section */}
      <section className="testimonials-section w-100 d-flex flex-column align-items-center">
        <h1 className="testimonial-header">Client <span style={{ color: "#3BB879" }}>Testimonials</span></h1>
        <p className="testimonial-description">Quality, reliability, speed and real savings</p>

        <div id="testimonialCarousel" className="carousel slide" data-bs-ride="carousel">
          <TestimonialsCarousel />
        </div>
      </section>
    </section>
  )
}

export default Home;
