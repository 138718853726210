/* eslint-disable no-unused-vars */
import React from 'react';
import ReactECharts from 'echarts-for-react';
import '../../styles/components/agentResults/WebappTestResult.css';
import { formatCurrentDateTime } from '../../utils/formatCurrentDateTime';
import { FaEdge } from "react-icons/fa";
import { FaFirefoxBrowser } from "react-icons/fa";
import { FaChrome } from "react-icons/fa";

const BrowserIcons = ({ browsers }) => {
  const iconStyle = { fontSize: '1.5vw', marginRight: '0.5vw' };
  
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {browsers.includes('chrome') && <FaChrome style={iconStyle} />}
      {browsers.includes('firefox') && <FaFirefoxBrowser style={iconStyle} />}
      {browsers.includes('edge') && <FaEdge style={iconStyle} />}
    </div>
  );
};

const WebappTestResult = ({ resultData }) => {
  if (!resultData) return null;

  const { 
    testrun_status_details: details,
    pass_fail_count_per_user_with_browser,
    execution_time_per_user_with_browser
  } = resultData;

  // Prepare data for pass/fail count chart
  const browserData = pass_fail_count_per_user_with_browser.map(item => ({
    name: `${item.browser_name} ${item.browser_version}`,
    passed: parseInt(item.passed),
    failed: parseInt(item.failed)
  }));

  // Bar Chart Options for Pass/Fail Distribution
  const passFailBarOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: ['Passed', 'Failed']
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: browserData.map(item => item.name),
      axisTick: {
        alignWithLabel: true
      },
      name: 'Browsers',
      nameLocation: 'middle',
      nameGap: 35,
      axisLabel: {
        interval: 0,
      }
    },
    yAxis: {
      type: 'value',
      name: 'Count',
      nameLocation: 'middle',
      nameGap: 40
    },
    series: [
      {
        name: 'Passed',
        type: 'bar',
        data: browserData.map(item => item.passed),
        itemStyle: {
          color: '#28a745'
        }
      },
      {
        name: 'Failed',
        type: 'bar',
        data: browserData.map(item => item.failed),
        itemStyle: {
          color: '#DE4C44'
        }
      }
    ]
  };

  // Prepare data for execution time chart
  const executionTimeOptions = {
    tooltip: {
      trigger: 'axis',
      formatter: function(params) {
        return `${params[0].name}<br/>${params[0].value / 1000}s`;
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: execution_time_per_user_with_browser.map(item => 
        `${item.browser_name} ${item.browser_version}`
      ),
      name: 'Browsers',
      nameLocation: 'middle',
      nameGap: 35,
      axisLabel: {
        interval: 0,
      }
    },
    yAxis: {
      type: 'value',
      name: 'Time (ms)',
      nameLocation: 'middle',
      nameGap: 40,
      axisLabel: {
        formatter: function(value) {
          return `${value / 1000}s`;
        }
      }
    },
    series: [{
      data: execution_time_per_user_with_browser.map(item => item.duration),
      type: 'bar',
      itemStyle: {
        color: '#20264D'
      }
    }]
  };

  // Extract only unique browser names
  const uniqueBrowsers = [...new Set(details.map(detail => 
    detail.browser_name
  ))].join(', ');

  return (
    <div className='multiple-browser-test-result'>
      <div className='test-result-header'>
        <p>The Multiple Browser testing was successfully completed at {formatCurrentDateTime()}.</p>
      </div>

      <div className='test-stats-grid'>
        <div className='stat-card'>
          <h3>{details[0].total}</h3>
          <p>Total Tests</p>
        </div>
        
        <div className='stat-card success'>
          <h3>{details[0].pass}</h3>
          <p>Passed</p>
        </div>
        
        <div className='stat-card error'>
          <h3>{details[0].fail}</h3>
          <p>Failed</p>
        </div>
        
        <div className='stat-card'>
          <h3>{(details[0].time_taken).toFixed(1)}s</h3>
          <p>Total Time</p>
        </div>
        
        <div className='stat-card'>
          <h3 className="mt-2"><BrowserIcons browsers={details.map(detail => detail.browser_name)} /></h3>
          <p>Browser{details.length > 1 ? 's' : ''}</p>
        </div>
      </div>

      <div className='test-charts'>
        <div className='chart-container'>
          <h4>Pass/Fail Distribution per Browser</h4>
          <ReactECharts option={passFailBarOptions} style={{ height: '400px' }} />
        </div>
        
        <div className='chart-container'>
          <h4>Execution Time per Browser</h4>
          <ReactECharts option={executionTimeOptions} style={{ height: '400px' }} />
        </div>
      </div>
    </div>
  );
};

export default WebappTestResult;
