/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { postApiCall } from '../../app/apiCalls';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript'; // Adjust based on the language of your code
import 'prismjs/themes/prism.css'; // Example style, you can use another
import '../../styles/components/agentResults/UiAutomationResult.css'; // Import the CSS file
import { FaFile } from "react-icons/fa6";
import { FaFolder } from "react-icons/fa6";

const renderFileMap = (fileMap, handleFileClick, handleFolderClick, updateFileMap, parentPath = '') => {
  return (
    <ul>
      {Object.entries(fileMap).map(([name, children]) => {
        const isFile = name.includes('.');
        const fullPath = `${parentPath}/${name}`;

        return (
          <li key={fullPath}>
            <div
              className={isFile ? 'file-item' : 'folder-item'}
              onClick={() => isFile ? handleFileClick(fullPath, name) : handleFolderClick(fullPath, name)}
            >
              {isFile ? <FaFile /> : <FaFolder />} {name}
            </div>
            {!isFile && Object.keys(children).length > 0 && (
              renderFileMap(children, handleFileClick, handleFolderClick, updateFileMap, fullPath)
            )}
          </li>
        );
      })}
    </ul>
  );
};

const UiAutomationResult = ({ resultData, presignedURL, setPresignedURL, setFileContentToCopy }) => {
  const [fileContent, setFileContent] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState('feature_recording_file');
  const [fileMap, setFileMap] = useState({});
  const [updateFileMap, setUpdateFileMap] = useState(0);
  const [currentFolder, setCurrentFolder] = useState('/');

  useEffect(() => {
    if (resultData) {
      listFiles(resultData.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultData]);

  const updateNestedObject = (obj, path, value) => {
    let currentFileMap = obj;
    let currentPath = path.split('/');
    currentPath = currentPath.filter((item) => item !== '');
    console.log('currentPath ', currentPath);
    
    // get to the object
    for (let i = 0; i < currentPath.length; i++) {
     if(currentFileMap[currentPath[i]])
      currentFileMap = currentFileMap[currentPath[i]]
    }
    console.log('currentFileMap before mod ', currentFileMap);
    
    for (let i = 0; i < value.length; i++) {
        currentFileMap[value[i]] = {};
    }
  
    return obj;
  };

  const listFiles = (automationId, currentPath = "/") => {
    postApiCall('/list-s3-objects', {
      folder_name: `${automationId}${currentPath}`,
    })
    .then((response) => {
      if (response) {
        // convert array response into object like { arrayElement: {}, ... }
        const folderContent = response;
        console.log('folderContent ', folderContent);
        const updatedFileMap = updateNestedObject(fileMap, currentPath, folderContent);
        console.log('updatedFileMap ', updatedFileMap);
        setUpdateFileMap(updateFileMap + 1);
        setFileMap(updatedFileMap);
      }
    }).catch((error) => {
      console.error(error);
    })
  };

  const fetchGeneratedFile = async (s3ObjectKey) => {
    try {
      const response = await postApiCall('/automate-ai/presigned-url', {
        file_name: s3ObjectKey,
        method: 'get_object'
      });
      if (response?.['pre-signed_url']) {
        if(s3ObjectKey.includes('.zip')) {
          // download the zip file
          const downloadUrl = response?.['pre-signed_url'];
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', resultData.generated_automation_file_s3_object_key.split('/').pop()); // Set the filename for download
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return;
        }

        setPresignedURL(response?.['pre-signed_url']);
        fetchFileContent(response['pre-signed_url']);
      } else {
        throw new Error("Failed to get presigned URL");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Error fetching presigned URL. Please try again.");
    }
  };

  const fetchFileContent = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch file content");
      }
      const text = await response.text(); // Fetch the content as text
      setFileContent(text); // Set the content directly
      setFileContentToCopy(text);
      setErrorMessage('');
    } catch (error) {
      console.error(error);
      setErrorMessage("Error fetching file content. Please try again.");
    }
  };

  const handleFileClick = (filePath, fileName) => {
    console.log('filePath ', filePath, 'fileName ', fileName);
    // remove leading slash
    if (filePath.startsWith('/')) {
      filePath = filePath.substring(1);
    }
    fetchGeneratedFile(`${resultData.id}/${filePath}`);
  };

  const handleFolderClick = (folderPath, folderName) => {
    console.log('folderPath ', folderPath, 'folderName ', folderName);
    listFiles(resultData.id, folderPath);
  };

  return (
    <div className='ui-automation-result'>
      <p><a href="https://github.com/applied-ai-consulting/aiTest-BDD-AutomationFramework" target="_blank" rel="noreferrer">Click here</a> to merge generated UI Automation Code in Base aiTest BDD Automation Framework.</p>

      <div className='ui-automation-result-grid'>
        <div className='file-explorer'>
          <h4>Generated Files</h4>
          <p className='file-explorer-description' >Click the file to see its code. <br/> <strong>Note: </strong>Zip file will be downloaded on click.</p>

          <div className='file-list'>
            {(Object.keys(fileMap).length > 0) ? renderFileMap(fileMap, handleFileClick, handleFolderClick, updateFileMap) : <p>No files found</p>}
          </div>
        </div>
        <div className='code-area'>
          {errorMessage ? (
            <p className='error-message'>{errorMessage}</p>
          ) : (
            fileContent?.length === 0 ? (
              <>
              <p>No file selected</p>
              <p>Click on a file to see its code.</p>
              </>
            ) :
            <Editor
              value={fileContent}
              onValueChange={code => {setFileContent(code); setFileContentToCopy(code);}}
              highlight={code => highlight(code, languages.js)} // Adjust the language as needed
              padding={10}
              disabled={true}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                border: '1px solid #DDDDDD',
                borderRadius: '0.56vw',
                backgroundColor: '#f5f5f5',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UiAutomationResult;

